import CertCard from "./CertCard.js"
import { useEffect, useState } from 'react'

export default function Ydelser(){
    const [loaded, setLoaded] = useState(true);

    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
      setWindowDimension(window.innerWidth);
    }, []);
  
    useEffect(() => {
      function handleResize() {
        setWindowDimension(window.innerWidth);
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowDimension <= 640;

    return (
        <div className="cont mt-4 text-white">
            <a id="ydelser" className="anchor"></a>
            <h1 className="text-4xl font-bold text-center" style={{margin: "24px", marginTop: "40px"}}>Ydelser</h1>
            {isMobile ? (
                <>
                    <p className="font-light text-center" style={{margin: "24px", fontSize: "20px"}}>din-regnskabskonsulent.dk ApS tilbyder at hjælpe små og mellemstore virksomheder med alle opgaver indenfor bogføring, regnskab og administration. Jeg hjælper både med enkelte opgaver og hele bogholderiet.</p>           
                    <p className="font-light text-center" style={{margin: "24px", fontSize: "20px"}}>Holder du til i nærheden af Tune, f. eks. Greve, Karlslunde, Solrød, Hedehusene eller i lignende afstand, vil der være mulighed for at opgaverne kan udføres på din adresse. Ellers er det jo heldigvis så nemt med digital bogføring, at det kan udføres overalt, så der er også mulighed for at opgaverne løses fra mit eget hjemmekontor.</p>
                    <p className="font-light text-center" style={{margin: "24px", fontSize: "20px"}}>Jeg tilbyder assistance med følgende ydelser:</p>
                </>
            ) : (
                <>
                    <p className="text-2xl font-light text-center" style={{margin: "24px"}}>din-regnskabskonsulent.dk ApS tilbyder at hjælpe små og mellemstore virksomheder med alle opgaver indenfor bogføring, regnskab og administration. Jeg hjælper både med enkelte opgaver og hele bogholderiet.</p>           
                    <p className="text-2xl font-light text-center" style={{margin: "24px"}}>Holder du til i nærheden af Tune, f. eks. Greve, Karlslunde, Solrød, Hedehusene eller i lignende afstand, vil der være mulighed for at opgaverne kan udføres på din adresse. Ellers er det jo heldigvis så nemt med digital bogføring, at det kan udføres overalt, så der er også mulighed for at opgaverne løses fra mit eget hjemmekontor.</p>
                    <p className="text-2xl font-light text-center" style={{margin: "24px"}}>Jeg tilbyder assistance med følgende ydelser:</p>
                </>
            )}
            {/* <div className="flex flex-col md:flex-row flex-wrap mt-4 gap-5"> */}
            <div className="grid grid-cols-1 md:grid-cols-4 justify-center mt-4 gap-5">
                <CertCard 
                    name="Løbende bogføring" 
                    desc="Ugentlig, månedlig eller kvartalbasis." />
                <CertCard 
                    name="Debitorstyring" 
                    desc="Fakturering og rykning af kunder." />
                <CertCard 
                    name="Kreditorstyring" 
                    desc="Oprettelse af leverandørbetalinger via din netbank eller økonomisystem." />
                <CertCard 
                    name="Lønadministration" 
                    desc="Udarbejdelse af løn efter din anvisning samt bogføring heraf." />
                <CertCard 
                    name="Momsindberetning" 
                    desc="Indberetning af moms, EU-salgslister, betaling, afstemning." />
                <CertCard 
                    name="Afstemninger" 
                    desc="Ugentlige, månedlige, kvartalvise eller årlige." />
                <CertCard 
                    name="Klargøring til revisor" 
                    desc="Alle afstemninger gøres klar og uploades sammen med dokumentation til revisor." />
                <CertCard 
                    name="Ansættelsesaftaler" 
                    desc="Oprettelse af ansættelsesaftaler som følger gældende lovgivning." />
                <CertCard 
                    name="Diverse administrative opgaver" 
                    desc="Efter dine ønsker." />
                <CertCard 
                    name="Udarbejdelse af budget" 
                    desc="Få overblik over dine forventede indtægter og udgifter." />
                <CertCard 
                    name="Udarbejdelse af rapporter" 
                    desc="Udarbejdes efter dine behov og ønsker" />
                <CertCard 
                    name="Likviditetsstyring" 
                    desc="Få overblik over din likviditet over en valgt periode." />
                <CertCard 
                    name="Projektstyring" 
                    desc="Få overblik over økonomien i dine projekter." />
                <CertCard 
                    name="Skattepligtig indkomst" 
                    desc="Beregning af din skattepligtige indkomst." />
                <CertCard 
                    name="Årsregnskab og selvangivelse" 
                    desc="Udarbejdelse og upload af årsregnskab og selvangivelse." />
            </div>
        </div>
    )
}
