import {useEffect, useState} from 'react'

import profile from '../assets/IMG_0364.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleArrowRight, } from "@fortawesome/free-solid-svg-icons";
import {  faGithub, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import HrCurve from './HrCurve';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  shape: {
    borderRadius: 32,
  },
  palette: {
    primary: {
      main: '#8b704b'
    },
  }
});

export default function Hiro () {

    const [loaded, setLoaded] = useState(true);

    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
      setWindowDimension(window.innerWidth);
    }, []);
  
    useEffect(() => {
      function handleResize() {
        setWindowDimension(window.innerWidth);
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowDimension <= 640;

    return (
        <>
        {/* {loaded ?
        <div
            className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center"
        >Loading...</div>
            :
            null
        } */}
        {isMobile ? (
                <div id="home" className="text-center flex w-full flex-col md:flex-row gap-0 items-center justify-center text-white relative">
                    <div className='md:w-3/6 md:p-4'>
                        {isMobile ? (
                            <img data-aos="fade-in" data-aos-duration="500" style={{borderRadius: "50%", border: "3px solid #d1b479", marginTop: "112px", marginLeft: "auto", marginRight: "auto"}} rounded data-aos-offset="200" width={"65%"} height={"65%"} src={profile} alt="profile" onLoad={() => setLoaded(true)} />
                        ) : (
                            <img data-aos="fade-in" data-aos-duration="500" style={{borderRadius: "50%", border: "3px solid #d1b479", marginLeft: "136px"}} rounded data-aos-offset="200" width={"65%"} height={"65%"} src={profile} alt="profile" onLoad={() => setLoaded(true)} />
                        )}
                    </div>
                    <div className='md:w-3/6' data-aos="fade-in" data-aos-duration="500" data-aos-offset="100">
                        <div className="flex flex-col w-full mt-0" >
                            {/* <h1 className="text-xl text-gray-400">Hi, I'm</h1> */}
                            <h1 className="text-5xl font-bold" style={{marginTop: "24px"}}>Få hjælp til bogføring og regnskab</h1>
                            <HrCurve />
                            <p className="text-2xl text-gray-200">Leder du efter en dygtig, stabil og grundig bogholder eller regnskabskonsulent er du kommet til den helt rigtige side. <br/>
                            Du er velkommen til at gå på opdagelse på siden eller kontakte os for spørgsmål. <br/>
                            Udfyld formularen her på siden og du vil blive kontaktet på mail eller telefon alt efter dit ønske.</p>
                            {/* <p className="text-md font-light text-gray-400 ">Særlig passioneret inden for frontend-udvikling, design, men er også erfaren i backend. Kan bidrage med den nyeste viden inden for IT-udvikling. Stor fokus på brugeroplevelse. Følger de nyeste teknologi trends og lærer nye færdigheder.</p> */}
                        </div>
                        <br/>
                        {/* <a href='http://google.com' className='mt-2 block'>Gå til min blog <FontAwesomeIcon className='ml-2' icon={faCircleArrowRight}/> </a> */}
                        <ul className='flex mt-2 gap-4 items-center justify-center'>
                            <li>
                                {/* <Button href="#home" rel="noreferrer" target="_blank" className="rounded-full px-4 py-1 border border border-4" style={{backgroundColor: "#8b704b", borderColor: "#684f34", fontSize: "20px"}}>Kontakt</Button> */}
                                <ThemeProvider theme={theme}>
                                    <Button href="#form" size='large' color='primary' variant='contained'>Kontakt</Button>
                                </ThemeProvider>
                            </li>
                            {/* <li>
                                <a href='https://www.linkedin.com/in/nikolaj-iversen-525279224/' rel="noreferrer" target="_blank"><FontAwesomeIcon size='2xl' icon={faLinkedinIn} /></a>
                            </li> */}
                            {/* <li>
                                <a href='https://github.com/gosuenesy' rel="noreferrer" target="_blank"><FontAwesomeIcon size='2xl' icon={faGithub} /></a>
                            </li>  */}
                        </ul>
                        
                    </div>
                    {/* <img src={hr} className="w-full md:h-2 absolute bottom-0" alt="hr" /> */}
                </div>
        ) : (
            <div id="home" className="flex w-full h-screen flex-col md:flex-row gap-0 items-center justify-center text-white relative" style={{marginTop: "64px"}}>
                <div className='md:w-3/6 md:p-4'>
                    {isMobile ? (
                        <img data-aos="fade-in" data-aos-duration="500" style={{borderRadius: "50%", border: "3px solid #d1b479", marginTop: "136px"}} rounded data-aos-offset="200" width={"65%"} height={"65%"} src={profile} alt="profile" onLoad={() => setLoaded(true)} />
                    ) : (
                        <img data-aos="fade-in" data-aos-duration="500" style={{borderRadius: "50%", border: "3px solid #d1b479", marginLeft: "136px"}} rounded data-aos-offset="200" width={"65%"} height={"65%"} src={profile} alt="profile" onLoad={() => setLoaded(true)} />
                    )}
                </div>
                <div className='md:w-3/6' data-aos="fade-in" data-aos-duration="500" data-aos-offset="100">
                    <div className="flex flex-col w-full mt-0" >
                        {/* <h1 className="text-xl text-gray-400">Hi, I'm</h1> */}
                        <h1 className="text-6xl font-bold" >Få hjælp til bogføring og regnskab</h1>
                        <hr style={{border: "0px", width: "20%", height: "3px", marginTop: "32px", marginBottom: "32px", background: "#d1b479"}}/>
                        {/* <p className="text-2xl text-gray-200">Leder du efter en dygtig, stabil og grundig bogholder eller regnskabskonsulent er du kommet til den helt rigtige side. <br/>
                        Du er velkommen til at gå på opdagelse på siden eller kontakte os for spørgsmål. <br/>
                        Udfyld formularen her på siden og du vil blive kontaktet på mail eller telefon alt efter dit ønske.</p> */}
                        <p className="text-2xl text-gray-200">Vil du have dit regnskab lavet på en professionel måde er du kommet til det rigtige sted.
                        Hos din-regnskabskonsulent.dk ApS bliver der gået meget op i at opgaverne bliver udført omhyggeligt og korrekt og der bliver ikke gået på kompromis med kvaliteten. <br/>
                        Udfyld formularen her på siden og du vil blive kontaktet på mail eller telefon alt efter dit ønske.</p>
                        {/* <p className="text-md font-light text-gray-400 ">Særlig passioneret inden for frontend-udvikling, design, men er også erfaren i backend. Kan bidrage med den nyeste viden inden for IT-udvikling. Stor fokus på brugeroplevelse. Følger de nyeste teknologi trends og lærer nye færdigheder.</p> */}
                    </div>
                    <br/>
                    {/* <a href='http://google.com' className='mt-2 block'>Gå til min blog <FontAwesomeIcon className='ml-2' icon={faCircleArrowRight}/> </a> */}
                    <ul className='flex mt-2 gap-4 items-center'>
                        <li>
                            {/* <Button href="#home" rel="noreferrer" target="_blank" className="rounded-full px-4 py-1 border border border-4" style={{backgroundColor: "#8b704b", borderColor: "#684f34", fontSize: "20px"}}>Kontakt</Button> */}
                            <ThemeProvider theme={theme}>
                                <Button href="#form" size='large' color='primary' variant='contained'>Kontakt</Button>
                            </ThemeProvider>
                        </li>
                        {/* <li>
                            <a href='https://www.linkedin.com/in/nikolaj-iversen-525279224/' rel="noreferrer" target="_blank"><FontAwesomeIcon size='2xl' icon={faLinkedinIn} /></a>
                        </li> */}
                        {/* <li>
                            <a href='https://github.com/gosuenesy' rel="noreferrer" target="_blank"><FontAwesomeIcon size='2xl' icon={faGithub} /></a>
                        </li>  */}
                    </ul>
                    
                </div>
            {/* <img src={hr} className="w-full md:h-2 absolute bottom-0" alt="hr" /> */}
            </div>
        )}
        </>
    )
}
