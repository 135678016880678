import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import profile from '../assets/IMG_9370.jpg';
import { useEffect, useState } from 'react'

export default function OmMig(){
    const [loaded, setLoaded] = useState(true);

    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
      setWindowDimension(window.innerWidth);
    }, []);
  
    useEffect(() => {
      function handleResize() {
        setWindowDimension(window.innerWidth);
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const isMobile = windowDimension <= 640;

    return (
        <div style={{textAlign: 'center', marginTop: "40px"}} className="cont mt-4 text-white">
            <a id="ommig" className="anchor"></a>
            <h1 className="text-4xl font-bold text-center" style={{margin: "24px"}}>Om mig</h1>
            <p className="text-2xl font-light text-center" style={{margin: "24px"}}>Jeg hedder Lisa. Jeg bor i Tune og har mere end 30 års erfaring med bogholderi og regnskab. Jeg har opnået en god, bred og solid erfaring og har indgående kendskab til alle funktioner indenfor økonomi og har længere ansættelser som regnskabsansvarlig og regnskabschef med på CV'et</p>
            <p className="text-2xl font-light text-center" style={{margin: "24px"}}>Vil du vide mere om, hvad jeg har lavet i mine år som arbejdstager er du velkommen til at læse mere på min linkedinprofil, ved at klikke herunder.</p>
            {/* <div className="flex justify-center">
                <div className="w-24 h-24 bg-dark bg-dark-200 rounded-md py-4 px-4"> */}
                    <a href='https://www.linkedin.com/in/lisa-skovgaard-larsen-027b4a1a2/' rel="noreferrer" target="_blank"><FontAwesomeIcon className="fa-5x" icon={faLinkedinIn} style={{margin: "24px", color: "#d1b479"}}/></a>
                {/* </div>
            </div> */}
            <div className="flex justify-center">
                {isMobile ? (
                    <img style={{borderRadius: "50%", border: "3px solid #d1b479", margin: "24px"}} rounded width={"50%"} height={"50%"} src={profile} alt="profile" />
                ) : (
                    <img style={{borderRadius: "50%", border: "3px solid #d1b479", margin: "24px"}} rounded width={"20%"} height={"20%"} src={profile} alt="profile" />    
                )}
            </div>
        </div>
    )
}
