import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import logo from '../assets/DRlogo2.png';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { slide as Menu } from 'react-burger-menu';
import { useState } from 'react'

const theme = createTheme({
  shape: {
    borderRadius: 32,
  },
  palette: {
    primary: {
      main: '#8b704b'
    },
  }
});


export default function NavbarMobile() {
  const [isOpen, setOpen] = useState(false)
  
  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }

  return (
    <div className='fixed z-50 w-full top-0 left-0 px-8 py-4 lg:px-20 xl:px-36' style={{backgroundColor: "#252525"}}>
        <div className="flex justify-between items-center text-white">
            <a href="#home"><img src={logo} className="w-96" style={{width: "96px"}} alt="logo" /></a>
            <Menu
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
            right>
              <a href="#home" onClick={closeSideBar} className="hover:text-test1 text-center text-2xl menu-item" style={{margin: "12px"}}>Forside</a>
              <a href="#ydelser" onClick={closeSideBar} className="hover:text-test1 text-center text-2xl menu-item" style={{margin: "12px"}}>Ydelser</a>
              <a href="#priser" onClick={closeSideBar} className="hover:text-test1 text-center text-2xl menu-item" style={{margin: "12px"}}>Priser</a>
              <a href="#ommig" onClick={closeSideBar} className="hover:text-test1 text-center text-2xl menu-item" style={{margin: "12px"}}>Om</a>
              <ThemeProvider theme={theme}>
                <div className="flex justify-center">
                      <Button href="#form" size='large' color='primary' variant='contained' style={{margin: "12px"}} className='menu-item' onClick={closeSideBar}>Kontakt</Button>
                </div>
              </ThemeProvider>
              <div className="flex justify-center items-center" style={{marginTop: "16px"}}>
                <p>
                  <a style={{fontSize: "14px"}}>
                    <FontAwesomeIcon size='1xl' color="#d1b479" icon={faMobile} /> <a href="tel:28633400" className="hover:text-test1 menu-item" style={{fontSize: "14px"}} onClick={closeSideBar}>28 63 34 00<br/></a>
                    <FontAwesomeIcon size='1xl' color="#d1b479" icon={faEnvelope} /> <a href="mailto:lisa.skovgaard.larsen@gmail.com" className="hover:text-test1 menu-item" style={{fontSize: "14px"}} onClick={closeSideBar}>info@din-regnskabskonsulent.dk</a>
                  </a>
                </p>
              </div>
            </Menu>
        </div>
    </div>
    )
}
