import HrCurve from "./HrCurve";

export default function CertCard(props){
    return (
        <a href={props.img}>
        <div data-aos="fade-in" data-aos-duration="500" data-aos-offset="100" className="w-full h-full bg-dark-200 rounded-md py-4 px-4">
            {/* <img src={props.img} className="w-full h-56 mx-auto object-cover" alt={props.name}></img> */}
            {/* <img src={props.img} className="h-56 mx-auto object-cover" alt={props.name}></img> */}
            <div className="mt-2">
                <h1 className="font-bold md:text-xl" style={{fontSize: "24px"}}>{props.name}</h1>
                <hr style={{border: "0px", width: "20%", height: "3px", marginTop: "14px", marginBottom: "8px", background: "#d1b479"}}/>
                <p className="font-light md:text-lg">{props.issued}</p>
                <p className="font-light md:text-lg">{props.desc}</p>
                {/* <p className="font-light text-gray-400">{props.date}</p> */}
            </div>
        </div>
        </a>
    )
}
