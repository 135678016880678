import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import 'react-toastify/dist/ReactToastify.min.css';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import CheckBox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Privatlivspolitik from '../assets/Privatlivspolitik GDPR.pdf';

const theme = createTheme({
  shape: {
    borderRadius: 32,
  },
  palette: {
    primary: {
      main: '#8b704b'
    },
  }
});


const ContactForm = () => {

  const [windowDimension, setWindowDimension] = useState(null);
  
  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);
  
  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }
  
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  const isMobile = windowDimension <= 640;
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [disabled, setDisabled] = useState(false);

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Din besked er sendt!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { navn, email, tlf, firma, cvr, besked } = data;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params
      const templateParams = {
        navn,
        email,
        tlf,
        firma,
        cvr,
        besked
      };

      // Use emailjs to email contact form data
      await emailjs.send(
        "default_service",
        "template_74jocaj",
        templateParams,
        "SQjSjW9P0Ens5mt9N"
      );

      // Reset contact form fields after submission
      reset();
      // Display success toast
      toastifySuccess();
      // Re-enable form submission
      setDisabled(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='cont ContactForm text-center'>
        <div className='row'>
          <div className='contactForm'>
            <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
              {/* Row 1 of form */}
              <a id="form" className="anchor"></a>
              <p style={{color: "#fff", fontSize: "36px", fontWeight: "bold", marginTop: "40px"}}>Kontakt mig via nedstående formular</p>
              <div className='row formRow'>
                <div style={{margin: 24}}>
                  <TextField
                    sx={{ input: { color: '#fff', backgroundColor: '#252525', borderRadius: 2, width: "100%" } }}
                    type='text'
                    name='navn'
                    {...register('navn', {
                      required: {
                        value: true,
                        message: 'Indtast navn'
                      },
                      maxLength: {
                        value: 30,
                        message: '30 eller færre tegn'
                      }
                    })}
                    className='form-control formInput'
                    label='Navn'
                    InputLabelProps={{
                      style: { color: '#fff' },
                    }}
                  ></TextField>
                  {errors.navn && <span className='errorMessage'><br/>{errors.navn.message}</span>}
                </div>
                <div className='col-6' style={{margin: 24}}>
                  <TextField
                    sx={{ input: { color: '#fff', backgroundColor: '#252525', borderRadius: 2, width: "100%" } }}
                    type='email'
                    name='email'
                    {...register('email', {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                    })}
                    className='form-control formInput'
                    label='E-mail'
                    InputLabelProps={{
                      style: { color: '#fff' },
                    }}
                  ></TextField>
                  {errors.email && (
                    <span className='errorMessage'><br/>Indtast en korrekt e-mail addresse</span>
                  )}
                </div>
              </div>
              <div className='row formRow'>
                <div className='col' style={{margin: 24}}>
                  <TextField
                    sx={{ input: { color: '#fff', backgroundColor: '#252525', borderRadius: 2, width: "100%" } }}
                    type='number'
                    name='tlf'
                    {...register('tlf', {
                      required: {
                        value: true,
                        message: 'Indtast Tlf.'
                      },
                      maxLength: {
                        value: 10,
                        message: 'Færre end 10 tegn'
                      }
                    })}
                    className='form-control formInput'
                    label='Tlf.'
                    InputLabelProps={{
                      style: { color: '#fff' },
                    }}
                  ></TextField>
                  {errors.tlf && (
                    <span className='errorMessage'><br/>{errors.tlf.message}</span>
                  )}
                </div>
              </div>
              {/* Row 2 of form */}
              <div className='col-6' style={{margin: 20}}>
                  <TextField
                    sx={{ input: { color: '#fff', backgroundColor: '#252525', borderRadius: 2, width: "100%%" } }}
                    type='text'
                    name='firma'
                    {...register('firma', {
                      required: {
                        value: true,
                        message: 'Indtast firmanavn'
                      },
                      maxLength: {
                        value: 40,
                        message: '40 eller færre tegn'
                      }
                    })}
                    className='form-control formInput'
                    label='Firma'
                    InputLabelProps={{
                      style: { color: '#fff' },
                    }}
                  ></TextField>
                  {errors.firma && <span className='errorMessage'><br/>{errors.firma.message}</span>}
                </div>
              <div className='row formRow'>
                <div className='col' style={{margin: 24}}>
                  <TextField
                    sx={{ input: { color: '#fff', backgroundColor: '#252525', borderRadius: 2, width: "100%" } }}
                    type='number'
                    name='cvr'
                    {...register('cvr', {
                      required: {
                        value: true,
                        message: 'Indtast CVR'
                      },
                      maxLength: {
                        value: 10,
                        message: 'Færre end 10 tegn'
                      }
                    })}
                    className='form-control formInput'
                    label='CVR'
                    InputLabelProps={{
                      style: { color: '#fff' },
                    }}
                  ></TextField>
                  {errors.cvr && (
                    <span className='errorMessage'><br/>{errors.cvr.message}</span>
                  )}
                </div>
              </div>
              {/* Row 3 of form */}
              <div className='row formRow'>
                <p style={{color: "#fff", fontSize: "28px"}}>Hvad drejer din henvendelse sig om?</p>
                <div className='col' style={{margin: 24}}>
                  {isMobile ? (
                    <TextField
                    style={{ backgroundColor: '#252525', borderRadius: 8, width: "100%" }}
                    type='text'
                    name='besked'
                    minRows={5}
                    maxRows={20}
                    multiline={true}
                    {...register('besked', {
                      required: true
                    })}
                    label='Besked'
                    inputProps={{
                        style: {color: '#fff'}
                    }}
                    InputLabelProps={{
                      style: { color: '#fff' },
                    }}
                  ></TextField>
                  ) : (
                  <TextField
                    style={{ backgroundColor: '#252525', borderRadius: 8, width: "50%" }}
                    type='text'
                    name='besked'
                    minRows={5}
                    maxRows={20}
                    multiline={true}
                    {...register('besked', {
                      required: true
                    })}
                    label='Besked'
                    inputProps={{
                        style: {color: '#fff'}
                    }}
                    InputLabelProps={{
                      style: { color: '#fff' },
                    }}
                  ></TextField>
                  )}
                  {errors.besked && <span className='errorMessage'><br/>Indtast din besked.</span>}
                </div>
              </div>
              <div className='row formRow' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CheckBox
                    required
                    sx={{
                      color: "white"
                    }}
                    color='primary'
                  />
                  <p style={{color: '#fff', fontSize: '16px'}}>Jeg accepterer <a href={Privatlivspolitik} style={{textDecorationLine: 'underline'}}>Vilkår og betingelser</a></p>
              </div>
              <ThemeProvider theme={theme}>
                <Button className='submit-btn' disabled={disabled} type='submit' size='large' color='primary' variant='contained' endIcon={<SendIcon />} style={{margin: 20}}>
                  Send
                </Button>
              </ThemeProvider>      
            </form>
          </div>
          <ToastContainer />
      </div>
      <br />
    </div>
  );
};

export default ContactForm;