import HrCurve from "./HrCurve";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    shape: {
      borderRadius: 32,
    },
    palette: {
      primary: {
        main: '#8b704b'
      },
    }
  });

export default function Priser(){
    return (
        <div className="cont mt-4 text-white">
            <a id="priser" className="anchor"></a>
            <h1 className="text-4xl font-bold text-center" style={{margin: "24px", marginTop: "40px"}}>Priser</h1>
            <div className="flex justify-center">
                <div className="w-96 bg-dark bg-dark-200 rounded-md py-4 px-4">
                    <h1 className="text-6xl font-bold text-center" style={{margin: "24px"}}>500,- </h1>
                    <p className="text-2xl font-light text-center" style={{margin: "24px"}}>pr. time</p>
                    <HrCurve />
                    <p className="text-2xl font-light text-center" style={{margin: "24px"}}><FontAwesomeIcon size='1xl' color="#d1b479" icon={faCheck} /> Alle ydelser</p>
                    {/* <p className="text-2xl font-light text-center" style={{margin: "24px"}}><FontAwesomeIcon size='1xl' color="#d1b479" icon={faCheck} /> Hele bogholderiet</p> */}
                    <p className="text-2xl font-light text-center" style={{margin: "24px"}}><FontAwesomeIcon size='1xl' color="#d1b479" icon={faCheck} /> Løbende rådgivning</p>
                </div>
            </div>
            <p className="text-2xl font-light text-center" style={{margin: "24px"}}>Ønsker du at have en fast pris på dine opgaver, kan vi også tilbyde en fast pris pr. måned eller kvartal. Det er dog ikke muligt at sætte en fast pris førend vi har hjulpet med dine opgaver i en periode.</p>
            <p className="text-2xl font-light text-center" style={{margin: "24px"}}>Ved kørsel afregnes efter Statens takst til 3,73 kr. pr. km.</p>
            <p className="text-2xl font-light text-center" style={{margin: "24px"}}>Alle priser er excl. moms.</p>
            <div className="flex justify-center">
                <ThemeProvider theme={theme}>
                    <Button href="#form" size='large' color='primary' variant='contained' style={{margin: "24px"}}>Kontakt</Button>
                </ThemeProvider>
            </div>
        </div>
    )
}
