import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import logo from '../assets/DRlogo1.png';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  shape: {
    borderRadius: 32,
  },
  palette: {
    primary: {
      main: '#8b704b'
    },
  }
});

export default function Navbar() {
    return (
    <div className='fixed z-50 w-full top-0 left-0 px-8 py-4 lg:px-20 xl:px-36' style={{backgroundColor: "#252525"}}> 
    {/* bg-dark-400 */}
        <div className="flex justify-between items-center text-white">
            <a href="#home"><img src={logo} className="w-96" style={{width: "440px"}} alt="logo" /></a>
            <ul className="hidden md:flex" >
                {/* <li className="p-4"><a href="#home" className="hover:text-test1" style={{fontSize: "20px"}}>Info</a></li> */}
                <li className="p-4"><a href="#ydelser" className="hover:text-test1" style={{fontSize: "20px"}}>Ydelser</a></li>
                <li className="p-4"><a href="#priser" className="hover:text-test1" style={{fontSize: "20px"}}>Priser</a></li>
                <li className="p-4"><a href="#ommig" className="hover:text-test1" style={{fontSize: "20px"}}>Om</a></li>
                {/* <li className="p-4"><a href="#form" className="hover:text-test1" style={{fontSize: "20px"}}>Kontakt</a></li> */}
            </ul>
            <p className="p-4"><a style={{fontSize: "16px"}}>
            <FontAwesomeIcon size='1xl' color="#d1b479" icon={faMobile} /> <a href="tel:28633400" className="hover:text-test1" style={{fontSize: "16px"}}>28 63 34 00<br/></a>
            <FontAwesomeIcon size='1xl' color="#d1b479" icon={faEnvelope} /> <a href="mailto:lisa.skovgaard.larsen@gmail.com" className="hover:text-test1" style={{fontSize: "16px"}}>info@din-regnskabskonsulent.dk</a></a></p>
            {/* <a href="#form" rel="noreferrer" target="_blank" className="rounded-full px-4 py-1 border border border-4" style={{backgroundColor: "#8b704b", borderColor: "#684f34", fontSize: "20px"}}>Kontakt</a> */}
            <ThemeProvider theme={theme}>
                <Button href="#form" size='large' color='primary' variant='contained'>Kontakt</Button>
            </ThemeProvider>
            {/* bg-yellow-600 border-yellow-800 */}
        </div>
    </div>
    )
}
