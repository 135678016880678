import './App.css';
import {useEffect, useState} from 'react'
import Navbar from './components/Navbar';
import NavbarMobile from './components/NavbarMobile';
import Hiro from './components/Hiro';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HrCurve from './components/HrCurve';
import Ydelser from './components/Ydelser';
import Priser from './components/Priser';
import OmMig from './components/OmMig';
import CookieConsent from 'react-cookie-consent';
import Cookiepolitik from './assets/Cookiepolitik.pdf';


function App() {
  useEffect(() => {
    document.title = 'din-regnskabskonsulent.dk ApS';
    AOS.init();
  }, []);

  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;

  return (
    <div className="px-6 lg:px-20 xl:px-36 bg-dark-800">
      {isMobile ? (
        <NavbarMobile />
      ) : (
        <Navbar />
      )}
      <Hiro />
        <HrCurve />
      <Ydelser />
        <HrCurve />
      <Priser />
        <HrCurve />
      <OmMig />
        <HrCurve />
      <ContactForm />
      <Footer />
      {/* <CookieConsent
        location="bottom"
        buttonText="ACCEPTÉR"
        cookieName="https://din-regnskabskonsulent.dk/"
        style={{ background: "#252525" }}
        buttonStyle={{ color: "#fff", fontSize: "15px", borderRadius: "32px", fontFamily: 'Roboto', backgroundColor: "#8b704b", padding: "8px 16px", margin: "16px" }}
        expires={0}
      >Hjemmesiden bruger cookies til korrekt funktionalitet. Læs mere i vores <a href={Cookiepolitik} style={{textDecorationLine: 'underline'}}>cookiepolitik.</a></CookieConsent> */}
    </div>
  );
}

export default App;
