import HrCurve from './HrCurve'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faBuilding } from "@fortawesome/free-solid-svg-icons";

export default function Footer(){
  return (
    <div className="mt-4 rounded-md text-white px-8 py-4" style={{backgroundColor: "#252525"}}>
      <ul className="text-center">
        {/* <li className="p-1"><a href="#home" className="hover:text-test1" style={{fontSize: "16px"}}>Info</a></li> */}
        <li className="p-0"><a style={{fontSize: "16px"}}><FontAwesomeIcon size='1xl' color="#d1b479" icon={faLocationDot} /> Tune Parkvej 31, 4030 Tune</a></li>
        {/* <li className="p-0"><a style={{fontSize: "16px"}}>4030 Tune</a></li> */}
        <li className="p-0"><a style={{fontSize: "16px"}}><FontAwesomeIcon size='1xl' color="#d1b479" icon={faBuilding} /> CVR-nummer: 43 06 34 48</a></li>
      </ul>
      <HrCurve />
      {/* <img src={hr} className="mb-4 mt-4 w-full md:h-1 text-white bottom-0" alt="hr" /> */}
      <ul className="text-center">
        <p className="text-sm font-light text-center" style={{fontSize: "16px", margin: "4px"}}>Copyright © 2023 din-regnskabskonsulent ApS</p>
        <p className="text-sm font-light text-center" style={{fontSize: "16px", margin: "4px"}}>Design af <a href='https://www.nikolajiversen.dk/' target="_blank" className="hover:text-test1 text-sm font-light text-center" style={{fontSize: "16px"}}>Nikolaj Iversen</a></p>
      </ul>
    </div>
  )
}
